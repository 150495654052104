import moment from 'moment'
import _ from "lodash";


export default class CacciatoreMenu {

    static ITA_DAYS= ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];

    config={};
    validaMenu = [];

    constructor(config) {
        this.config = config;
        this.validaMenu[1] = this.valida1;
        this.validaMenu[2] = this.valida2;
        this.validaMenu[3] = this.valida3;
        this.validaMenu[4] = this.valida4;
    }

    isPiattoInMenu = (piatto, prezzo, giorno) => {
        
        if (piatto.giorni_consegna){
            //giorni_consegna
            const g = CacciatoreMenu.ITA_DAYS[moment(giorno).day()];
            if (piatto.giorni_consegna.indexOf(g.toLowerCase()) < 0){
                return false;
            }
        }

        if (prezzo === 1) {

            if (piatto.cat === 'secondi' && piatto.price > 13) {
                return false;
            }
            return true;
        } else if (prezzo === 2) {
            // TODO bis di
            if ((piatto.cat === 'antipastidelivery' || piatto.cat === 'primi') && piatto.price > 12) {
                if (piatto.title.toLowerCase().indexOf('bis di') > -1){
                    return true;
                }else{
                        return false;
                }
            } else if (piatto.cat === 'secondi' && piatto.price > 12 ) {
                return false;
            }
        } else if (prezzo === 3) {
            if (piatto.cat === 'antipastidelivery' && piatto.price > 13) {
                return false;
            } else if (piatto.cat === 'primi' && piatto.price > 12) {
                return false;
            } else if (piatto.cat === 'secondi' && piatto.price > 12) {
                return false;
            }
        } else if (prezzo === 4) {
            if (piatto.cat === 'antipastidelivery' && piatto.price > 13) {
                return false;
            } else if (piatto.cat === 'primi' && piatto.price > 14) {
                return false;
            } else if (piatto.cat === 'secondi' && piatto.price > 16 ) {
                return false;
            }
        }

        return true;

    }

    isMenuCompleto = (menu, prezzo) => {
        
        const groupByCat = _.groupBy(menu, 'cat');
        let numCategorie = 3;
        if (prezzo === 1){
            numCategorie = 2;
        }
        if (_.keys(groupByCat).length < numCategorie){
                return false;
        }

        let isCompleto = false;

        if (prezzo === 1) {
            if (groupByCat['primi'].length === 2 || (groupByCat['primi'].length === 1 && CacciatoreMenu.isPresente('bis di', menu))){
                isCompleto = true;
            }

            if ( (_.has(groupByCat, 'secondi') && groupByCat['secondi'].length === 1) || (_.has(groupByCat, 'antipastidelivery') &&  groupByCat['antipastidelivery'].length >= 1)){
                isCompleto = (isCompleto && true);
            }else{
                isCompleto = (isCompleto && false);
            }


        } else if (prezzo === 2) {
            if (groupByCat['antipastidelivery'].length > 0){
                isCompleto = true;
            }
            if (groupByCat['primi'].length === 2 || (groupByCat['primi'].length === 1 && CacciatoreMenu.isPresente('bis di', menu))){
                isCompleto = (isCompleto && true);
            }else{
                isCompleto = (isCompleto && false);
            }

            if (groupByCat['secondi'].length === 1){
                isCompleto = (isCompleto && true);
            }else{
                isCompleto = (isCompleto && false);
            }
        } else if (prezzo === 3) {

            if (groupByCat['antipastidelivery'].length > 0){
                isCompleto = true;
            }
            if (groupByCat['primi'].length === 2 || (groupByCat['primi'].length === 1 && CacciatoreMenu.isPresente('bis di', menu))){
                isCompleto = (isCompleto && true);
            }else{
                isCompleto = (isCompleto && false);
            }

            if (groupByCat['secondi'].length === 2){
                isCompleto = (isCompleto && true);
            }else{
                isCompleto = (isCompleto && false);
            }
        } else if (prezzo === 4) {
            
            if (groupByCat['antipastidelivery'].length > 0){
                isCompleto = true;
            }
            if (groupByCat['primi'].length === 2){
                isCompleto = (isCompleto && true);
            }else{
                isCompleto = (isCompleto && false);
            }

            if (groupByCat['secondi'].length === 2 || (groupByCat['secondi'].length === 1 && CacciatoreMenu.isPresente('bollito', menu))){
                isCompleto = (isCompleto && true);
            }else{
                isCompleto = (isCompleto && false);
            }

            
        }

        return isCompleto;

    }



    valida1 = (piatto, countByCat, piatti) => {

        if (piatto.cat === 'primi') {
            return this.hasExtra(piatto, countByCat, 'primi', piatti);
        } else if (piatto.cat === 'secondi') {
            if (countByCat['antipastidelivery'] >= 1 || countByCat['secondi'] >= 1) {
                return true;
            }
        } else if (piatto.cat === 'antipastidelivery') {
            if (countByCat['secondi'] > 0) {
                return true;
            } else {
                return CacciatoreMenu.hasAntipasto(piatto, 21, piatti);
            }
        }
        return false;
    }
    
    valida2 = (piatto, countByCat, piatti) => {

        if (piatto.cat === 'primi') {
            return this.hasExtra(piatto, countByCat, 'primi', piatti);
        } else if (piatto.cat === 'secondi' && countByCat['secondi'] >= 1) {
            return true;
        } else if (piatto.cat === 'antipastidelivery') {
            return CacciatoreMenu.hasAntipasto(piatto, 16, piatti);
        }

        return false;
    }
    
    valida3 = (piatto, countByCat, piatti) => {
        if (piatto.cat === 'primi') {
            return this.hasExtra(piatto, countByCat, 'primi', piatti);
        } else if (piatto.cat === 'secondi' && countByCat['secondi'] >= 2) {
            return true;
        } else if (piatto.cat === 'antipastidelivery') {
            return CacciatoreMenu.hasAntipasto(piatto, 21, piatti);
        }
        return false;
    }
    
    valida4 = (piatto, countByCat, piatti) => {
        if (piatto.cat === 'primi') {
            return this.hasExtra(piatto, countByCat, 'primi', piatti);
        } else if (piatto.cat === 'secondi') {
            return this.hasExtra(piatto, countByCat, 'secondi', piatti);
        } else if (piatto.cat === 'antipastidelivery') {
            return CacciatoreMenu.hasAntipasto(piatto, 21, piatti);
        }
        return false;//
    }

    hasExtra = (piatto, countByCat, categoria, piatti) => {
        let myInstance = this;
        if (countByCat[categoria] >= 2) {
            return true;
        } else if (countByCat[categoria] === 1) {
            // se il bollito è quello corrente gli altri sono disabilitati
            // cioè quando ce n'è uno e il bollito è da disabilitare
            if (this.isExtra(piatto.title)) {
                return true;
            }
            // se il bollito è selezionato tutto il resto è da disabilitare
            const bollito = _.filter(piatti, function (p) {
                return p.selected && myInstance.isExtra(p.title);
            });
            if (bollito.length > 0) {
                return true;
            }
        }
        return false;
    }

    isExtra = (titolo) => {
        return  (_.filter(this.config.extra, function(e){
            return titolo.toLowerCase().indexOf(e) > -1;
        }).length > 0);
    }
    
    static hasAntipasto = (piatto, prezzoMassimo, piatti) => {
        let total = _.sumBy(piatti, function (p) {
            return p.selected ? p.price : 0;
        });

        if (total >= prezzoMassimo || (total + piatto.price) >= prezzoMassimo) {
            return true;
        }
        return false;
    }

    static isPresente = (titolo, menu) => {
        return _.find(menu, function(piatto){
              return piatto.title.toLowerCase().indexOf(titolo) > -1;  
        }) !== undefined;
    }

}
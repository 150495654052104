import _ from "lodash";
import React, { useState, useEffect } from "react";
import ServiceApi from './serviceApi';

const Piatti = ({ menu, categoria, prezzo, giorno, config, notifyChange }) => {

    const api = ServiceApi.getMenuMgr(config);
    const [piatti, setPiatti] = useState([]);
    const [portata, setPortata] = useState("");

    useEffect(() => {
        //console.log("effect1_"+categoria);

        let p = _.filter(menu[categoria], function (piatto) {
            return api.isPiattoInMenu(piatto, prezzo, giorno);
        });
        updatePiattiStatus(p);

        let titoloCategoria = categoria;
        if (categoria === 'antipastidelivery') {
            titoloCategoria = 'antipasti';
        }
        setPortata(titoloCategoria);
    }, [prezzo, menu]);//, portata, prezzo, menu


    const updatePiattiStatus = (plist) => {
        let countByCat = {};
        _.each(_.keys(menu), function (cat) {
            countByCat[cat] = _.filter(menu[cat], { selected: true }).length;
        });

        var changed = plist.map((piatto) => {
            piatto.disabled = isDisabled(piatto, countByCat);
            return piatto;
        });
        setPiatti(changed);
    }


    const handleChangePiatto = (e, piatto) => {
        piatto.selected = e.target.checked;
        updatePiattiStatus(piatti);
        notifyChange();
    }

    const isDisabled = (piatto, countByCat) => {
        if (!piatto.selected) {
            let funcValidazione = api.validaMenu[prezzo];
            return funcValidazione(piatto, countByCat, piatti);
        } else {
            return false;
        }
    }

    return (

        <div className="mb-3">
            <h3 className="mb-2" style={{ "textTransform": 'capitalize' }}>{portata}</h3>
            {piatti.sort((a, b) => a.title.localeCompare(b.title)).map((item) => (

                <div className="form-check mb-2" key={item.id + '_' + item.disabled}>
                    <input disabled={item.disabled} checked={item.selected} onChange={(e) => handleChangePiatto(e, item)} className="form-check-input" type="checkbox" id={item.id} value={item.title} />
                    <label className="form-check-label" htmlFor={item.id}>{item.title}</label>
                </div>

            ))}
        </div>


    );

}

export default Piatti;